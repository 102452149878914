import { StorageService } from './storage.service';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SigninService } from './signin.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService implements HttpInterceptor {
  constructor(
    private signinService: SigninService,
    private storageService: StorageService,
    private router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const excludePaths: string[] = ['/account/'];

    const matchedPath = excludePaths.filter((path) => req.url.includes(path));

    if (matchedPath.length === 0) {
      const identity: any = this.storageService.getToken();
      if (!identity) {
        this.router.navigate(['account/signin']);
      }
      // Clone the request to add the new header.
      const authReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${identity}`,
        },
      });
      // Pass on the cloned request instead of the original request.
      return next.handle(authReq).pipe(
        catchError((error: any) => {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else {
            if (error.status === 401) {
              this.signinService.doLogout();
              this.router.navigate(['account/signin']);
            }
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          }
          return throwError(errorMessage);
        })
      );
    }
    return next.handle(req);
  }
}
