import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowPointInfoComponent } from './show-point-info.component';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { MainPipeModule } from '../main-pipe/main-pipe.module';
import { PropertyInfoModule } from '../property-info/property-info.module';

@NgModule({
  imports: [
    CommonModule,
    NzGridModule,
    NzTableModule,
    NzTabsModule,
    NzCarouselModule,
    NzDividerModule,
    NzButtonModule,
    MainPipeModule,
    PropertyInfoModule,
  ],
  declarations: [ShowPointInfoComponent],
})
export class ShowPointInfoModule {}
