import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertyInfoComponent } from './property-info.component';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzCardModule } from 'ng-zorro-antd/card';
import { FormsModule } from '@angular/forms';
import { NgxEchartsModule } from 'ngx-echarts';
import { NzTabsModule } from 'ng-zorro-antd/tabs';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NzGridModule,
    NzDrawerModule,
    NzSpinModule,
    NzCarouselModule,
    NzTagModule,
    NzCardModule,
    NzTabsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
  declarations: [PropertyInfoComponent],
  exports: [PropertyInfoComponent],
})
export class PropertyInfoModule {}
