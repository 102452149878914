import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { NzCarouselComponent } from 'ng-zorro-antd/carousel';
import { SalesService } from 'src/app/services/sales.service';

@Component({
  selector: 'app-show-point-info',
  templateUrl: './show-point-info.component.html',
  styleUrls: ['./show-point-info.component.scss'],
})
export class ShowPointInfoComponent implements OnInit, AfterViewInit {
  @Input() property?: any;
  @Input() filterData?: any;
  @Input() isCheckboxShow?: any;
  @ViewChild(NzCarouselComponent) carousel: NzCarouselComponent;
  viewer: any;

  isShowPropertyDetail = false;
  showingProperty;

  tabIndex = 0;

  isPointLoading = false;
  tableData: any;
  selection = new SelectionModel<any>(true, []);

  isSelected = false;

  screens: any = [];
  screenSelection = new SelectionModel<any>(true, []);

  standingScreens: any = [];
  standingScreenSelection = new SelectionModel<any>(true, []);

  dualScreens: any = [];
  dualScreenSelection = new SelectionModel<any>(true, []);

  posters: any = [];
  posterSelection = new SelectionModel<any>(true, []);

  magicPosters: any = [];
  magicSelection = new SelectionModel<any>(true, []);

  salesUsedPointIds: any;

  constructor(private salesService: SalesService) {}

  ngOnInit() {
    const selectedRes = this.salesService.getSelectedResFromMemory();
    const filtered = selectedRes.selected.filter((element, index, array) => {
      return element.id === this.property.id;
    });

    this.isSelected = filtered.length > 0;

    this.findPointDetail();
  }

  ngAfterViewInit() {
    this.buildViewer();
  }

  showPropertyInfo(property: any) {
    property.propertyId = property.id;
    this.showingProperty = property;
    this.isShowPropertyDetail = true;
  }

  buildViewer() {
    this.viewerDestroy();

    const overlayClassName = 'cdk-overlay-pane';
    const elementsArr = document.getElementsByClassName(
      'cdk-overlay-container'
    );
    let container;
    for (const element of Array.from(elementsArr)) {
      if (element.querySelector('app-show-point-info')) {
        container = element;
        break;
      }
    }

    this.viewer = new Viewer(this.carousel.el, {
      container: container ? container : '',
      className: overlayClassName,
    });

    const viewerRootContainer = document.getElementsByClassName(
      'viewer-container'
    );
    this.carousel.el.addEventListener('show', () => {
      for (const ele of Array.from(viewerRootContainer)) {
        if (!ele.classList.contains(overlayClassName)) {
          ele.classList.add(overlayClassName);
        }
      }
    });

    this.carousel.el.addEventListener('hidden', () => {
      for (const ele of Array.from(viewerRootContainer)) {
        if (ele.classList.contains(overlayClassName)) {
          ele.classList.remove(overlayClassName);
        }
      }
    });
  }

  isAllSelected() {
    const allPoint = this.tableData.filter(
      (point) => this.salesUsedPointIds.indexOf(',' + point.id + ',') === -1
    );
    return this.selection.selected.length === allPoint.length;
  }

  singleSelectDeselect(event, point) {
    if (event) {
      if (this.salesUsedPointIds.indexOf(',' + point.id + ',') === -1) {
        this.selection.select(point);
      }
    } else {
      this.selection.deselect(point);
    }
    const currentAction = this.getCurrentAction();
    const currentCount = this.getCurrerntCount();
    const ids = [];
    this.selection.selected.forEach((pp) => {
      ids.push(pp.id);
    });
    this.property[currentAction] = ids;
    this.property[currentCount] = ids.length;
    this.checkPropertySelection();
  }

  getCurrentAction() {
    return this.tabIndex === 0
      ? 'pScreenIds'
      : this.tabIndex === 1
      ? 'pStandingScreenIds'
      : this.tabIndex === 2
      ? 'pDualScreenIds'
      : this.tabIndex === 3
      ? 'pPosterIds'
      : '';
  }

  getCurrerntCount() {
    return this.tabIndex === 0
      ? 'pScreen'
      : this.tabIndex === 1
      ? 'pStandingScreen'
      : this.tabIndex === 2
      ? 'pDualScreen'
      : this.tabIndex === 3
      ? 'pPoster'
      : '';
  }

  allToggle() {
    const actionIds = this.getCurrentAction();
    const counts = this.getCurrerntCount();

    this.property[actionIds] = [];
    this.property[counts] = 0;

    const isAllSelected = this.isAllSelected();
    if (isAllSelected) {
      this.selection.clear();
      this.property[actionIds] = [];
      this.property[counts] = 0;
      this.checkPropertySelection();
    } else {
      this.selection.clear();
      const ids = [];
      this.tableData.forEach((point) => {
        if (this.salesUsedPointIds.indexOf(',' + point.id + ',') === -1) {
          this.selection.select(point);
          ids.push(point.id);
        }
      });
      this.property[actionIds] = ids;
      this.property[counts] = ids.length;
      this.checkPropertySelection();
    }
  }

  checkPropertySelection() {
    const totalScreen = this.property.salesScreen;
    const totalStandingScreen = this.property.salesStandingScreen;
    const totalDualScreen = this.property.salesDualScreen;
    const totalPoster = this.property.salesPoster;
    const totalMagicPoster = this.property.salesMagicPoster;

    if (
      totalScreen === this.property.pScreen &&
      totalStandingScreen === this.property.pStandingScreen &&
      totalDualScreen === this.property.pDualScreen &&
      totalPoster === this.property.pPoster &&
      totalMagicPoster === this.property.pMagicPoster
    ) {
      this.isSelected = true;
      this.property.isChecked = true;
      this.property.isIndeterminate = false;
      this.salesService.removeProperty(this.property);
      this.salesService.addProperty(this.property);
    } else if (
      this.property.pScreen > 0 ||
      this.property.pStandingScreen > 0 ||
      this.property.pDualScreen > 0 ||
      this.property.pPoster > 0 ||
      this.property.pMagicPoster > 0
    ) {
      this.isSelected = true;
      this.property.isChecked = false;
      this.property.isIndeterminate = true;
      this.salesService.removeProperty(this.property);
      this.salesService.addProperty(this.property);
    } else {
      this.isSelected = false;
      this.property.isChecked = false;
      this.property.isIndeterminate = false;
      this.salesService.removeProperty(this.property);
    }
  }

  selectUnselect() {
    if (this.property.isChecked || this.property.isIndeterminate) {
      this.isSelected = false;
      this.salesService.resetProperty(this.property);
      this.clearAllSelection();
    } else {
      this.isSelected = true;
      this.salesService.selectProperty(this.property);
      this.setDefaultSelection();
    }
  }

  clearAllSelection() {
    this.screenSelection.clear();
    this.standingScreenSelection.clear();
    this.dualScreenSelection.clear();
    this.magicSelection.clear();
    this.posterSelection.clear();
    this.selection.clear();
  }

  findPointDetail() {
    this.salesService.isProgress = true;
    this.isPointLoading = true;
    this.salesService
      .findPointDetail(this.property.id, this.filterData)
      .subscribe((data: any) => {
        this.screens = data.screens;
        this.posters = data.posters;
        this.magicPosters = data.magicPosters;
        this.standingScreens = data.standingScreens;
        this.dualScreens = data.dualScreens;

        this.salesUsedPointIds = data.salesUsedPointIds;
        this.setDefaultSelection();

        this.isPointLoading = false;
        this.onSelectedIndexChange(this.tabIndex);
        this.salesService.isProgress = false;
      });
  }

  setDefaultSelection() {
    if (this.isSelected) {
      if (this.property.pScreenIds && this.property.pScreenIds.length) {
        const screenIds = this.property.pScreenIds;
        this.screens.forEach((screen) => {
          screenIds.forEach((screenId) => {
            if (screen.id === +screenId) {
              this.screenSelection.select(screen);
            }
          });
        });
      }

      if (
        this.property.pStandingScreenIds &&
        this.property.pStandingScreenIds.length
      ) {
        const standingScreenIds = this.property.pStandingScreenIds;
        this.standingScreens.forEach((screen) => {
          standingScreenIds.forEach((screenId) => {
            if (screen.id === +screenId) {
              this.standingScreenSelection.select(screen);
            }
          });
        });
      }

      if (this.property.pDualScreenIds && this.property.pDualScreenIds.length) {
        const dualScreenIds = this.property.pDualScreenIds;
        this.dualScreens.forEach((screen) => {
          dualScreenIds.forEach((screenId) => {
            if (screen.id === +screenId) {
              this.dualScreenSelection.select(screen);
            }
          });
        });
      }

      if (this.property.pPosterIds && this.property.pPosterIds.length) {
        const posterIds = this.property.pPosterIds;
        this.posters.forEach((poster) => {
          posterIds.forEach((posterId) => {
            if (poster.id === +posterId) {
              this.posterSelection.select(poster);
            }
          });
        });
      }

      if (
        this.property.pMagicPosterIds &&
        this.property.pMagicPosterIds.length
      ) {
        const magicPosterIds = this.property.pMagicPosterIds;
        this.magicPosters.forEach((magicPoster) => {
          magicPosterIds.forEach((magicPosterId) => {
            if (+magicPoster.id === +magicPosterId) {
              this.magicSelection.select(magicPoster);
            }
          });
        });
      }
    }
  }

  onSelectedIndexChange(index: any) {
    this.tabIndex = index;
    if (this.tabIndex === 0) {
      this.tableData = this.screens;
      this.selection = this.screenSelection;
    } else if (this.tabIndex === 1) {
      this.tableData = this.standingScreens;
      this.selection = this.standingScreenSelection;
    } else if (this.tabIndex === 2) {
      this.tableData = this.dualScreens;
      this.selection = this.dualScreenSelection;
    } else if (this.tabIndex === 3) {
      this.tableData = this.posters;
      this.selection = this.posterSelection;
    }
  }

  isPointAvailable(pointId: any) {
    return this.salesUsedPointIds.indexOf(',' + pointId + ',') !== -1;
  }

  viewerDestroy() {
    if (this.viewer) {
      this.viewer.destroy();
    }
  }
}
